import { useEffect, useState } from 'react';
import { Controller, ControllerRenderProps, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

import { IEventClub, IEventPlayer, IEventTeam } from '../Events';
import { useStyles } from '../Events.styles';
import { PlayersTable } from '../PlayersTable/PlayersTable';

interface ITeamDrawer {
    clubs: IEventClub[];
    drawer: 'edit' | 'create' | null;
    selectedTeam?: IEventTeam | null;
    teams: IEventTeam[];
    createPlayersList: (clubId: string, selectedPlayers: IEventPlayer[] | null) => IEventPlayer[];
    handleAction: (data: IEventTeam) => void;
    handleDrawerClose: () => void;
}

interface ITeamFilters {
    showLicensedPlayers: boolean;
    searchValue: string;
    maxYearOfBirth: string;
}

interface ITeamDrawerState {
    club: IEventClub | null;
    name: string;
    id: number;
    players: IEventPlayer[];
}

const initialState: ITeamDrawerState = {
    club: null,
    name: '',
    id: 0,
    players: [],
};

const initialFilters: ITeamFilters = {
    showLicensedPlayers: false,
    searchValue: '',
    maxYearOfBirth: '',
};

export const TeamDrawer = ({
    clubs,
    drawer,
    selectedTeam,
    teams,
    createPlayersList,
    handleAction,
    handleDrawerClose,
}: ITeamDrawer) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { control, watch, setValue, reset, handleSubmit } = useForm({
        defaultValues: initialState,
    });
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [filterCriteria, setFilterCriteria] = useState(initialFilters);
    const [originalPlayers, setOriginalPlayers] = useState<IEventPlayer[]>([]);

    useEffect(() => {
        if (drawer === 'edit' && selectedTeam) {
            const players = createPlayersList(selectedTeam.club?.id, selectedTeam.players);
            setValue('name', selectedTeam.name);
            setValue('club', selectedTeam.club);
            setValue('players', players);
            setOriginalPlayers(players);
            setValue('id', selectedTeam.id);
        }
    }, [drawer, selectedTeam, setValue, createPlayersList]);

    const players = watch('players');
    const setPlayers = (data: IEventPlayer[]) => setValue('players', data);
    const playersToRender = filterPlayers(players, filterCriteria);
    const totalSelectedPlayers = getAllSelectedPlayers(players);

    const clubId = watch('club.id');

    const { showLicensedPlayers, searchValue, maxYearOfBirth } = filterCriteria;

    const resetFilters = () => {
        setPage(0);
        setFilterCriteria(initialFilters);
    };

    const handleFilterChange = (key: string, value: ITeamFilters[keyof ITeamFilters]) => {
        setPage(0);
        setFilterCriteria(prev => ({
            ...prev,
            [key]: value,
        }));
    };

    const handleChangePage = (_event: unknown, newPage: number) => setPage(newPage);

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleClubChange = (
        _event: unknown,
        value: IEventClub | null,
        field: ControllerRenderProps<ITeamDrawerState, 'club'>
    ) => {
        field.onChange(value ? value : '');
        if (value) {
            const players = createPlayersList(value.id, null);
            setPlayers(players);
            setOriginalPlayers(players);
            setValue('name', value.name);
        } else {
            setPlayers([]);
            setValue('name', '');
        }
        resetFilters();
    };

    const handleTeamSubmit = (data: ITeamDrawerState) => {
        const newData = {
            ...data,
            uniqueId: selectedTeam?.uniqueId,
            players: data.players?.filter(player => player.isChosen) || [],
        } as IEventTeam;
        handleAction(newData);
        resetFilters();
        reset();
    };

    const handleCloseClick = () => {
        handleDrawerClose();
        resetFilters();
        reset();
    };

    return (
        <Dialog open={drawer !== null} fullWidth maxWidth="md">
            <form onSubmit={handleSubmit(handleTeamSubmit)}>
                <DialogTitle>{drawer === 'create' ? t('Events.addTeam') : t('Events.editTeam')}</DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Controller
                                name="club"
                                control={control}
                                render={({ field }) => (
                                    <Autocomplete
                                        options={clubs}
                                        getOptionLabel={option => option.name}
                                        onChange={(event, value) => handleClubChange(event, value, field)}
                                        value={clubs.find(club => club.id === field.value?.id) || null}
                                        renderInput={params => <TextField {...params} label={t('Events.chooseClub')} />}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={9}>
                            <Controller
                                name="name"
                                control={control}
                                render={({ field }) => (
                                    <TextField variant="outlined" fullWidth label={t('Events.teamName')} {...field} />
                                )}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Controller
                                name="id"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        type="number"
                                        variant="outlined"
                                        fullWidth
                                        label={t('Events.teamId')}
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={4} className={classes.licenseCheckbox}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={showLicensedPlayers}
                                        onChange={e => handleFilterChange('showLicensedPlayers', e.target.checked)}
                                        color="primary"
                                    />
                                }
                                label={t('Events.showLicensedPlayers') as string}
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label={t('Events.searchPlayer')}
                                value={searchValue}
                                onChange={e => handleFilterChange('searchValue', e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={3} container justifyContent="center">
                            <TextField
                                variant="outlined"
                                fullWidth
                                label={t('Events.maxYearOfBirth')}
                                value={maxYearOfBirth}
                                onChange={e => handleFilterChange('maxYearOfBirth', e.target.value)}
                                type="number"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <PlayersTable
                                clubId={clubId}
                                originalPlayers={originalPlayers}
                                page={page}
                                players={playersToRender}
                                rowsPerPage={rowsPerPage}
                                selectedTeam={selectedTeam}
                                teams={teams}
                                setOriginalPlayers={players => setOriginalPlayers(players)}
                                setPlayers={setPlayers}
                                handleChangePage={handleChangePage}
                                handleChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <DialogContentText>
                                {
                                    t('Events.totalSelectedPlayers', {
                                        count: totalSelectedPlayers,
                                    }) as string
                                }
                            </DialogContentText>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseClick} color="primary">
                        {t('Events.close')}
                    </Button>
                    <Button color="primary" variant="contained" type="submit">
                        {drawer === 'create' ? t('Events.save') : t('Events.edit')}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export function getYearOfBirth(birthDate: number) {
    const birth = new Date(birthDate);
    return birth.getFullYear();
}

function filterPlayers(players: IEventPlayer[], filterCriteria: ITeamFilters) {
    const { showLicensedPlayers, searchValue, maxYearOfBirth } = filterCriteria;

    const searchTerms = searchValue.toLowerCase().trim().split(' ');

    const filteredPlayers = players.filter(player => {
        const playerYearOfBirth = getYearOfBirth(player.bdat);
        const playerName = player.name.toLowerCase();

        const matchesSearch = searchTerms.every(term => playerName.includes(term));

        return (
            matchesSearch &&
            (maxYearOfBirth === '' || playerYearOfBirth >= parseInt(maxYearOfBirth, 10)) &&
            (!showLicensedPlayers || player.isExpired === false)
        );
    });

    return filteredPlayers;
}

function getAllSelectedPlayers(players: IEventPlayer[]) {
    const allSelectedPlayers = players?.filter(player => player.isChosen).length || 0;

    return allSelectedPlayers;
}
